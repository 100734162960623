
import { defineComponent, PropType } from 'vue'
import { CollectionInstruction, Instruction, InstructionDsp } from '../../../../../types/instruction_type'

export default defineComponent({
  name: 'BulkInstructionAutocomplete',
  props: {
    dsp: {
      type: String as PropType<InstructionDsp>,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      model: null,
      search: null as string | null,
      items: [] as TextValue[]
    }
  },
  methods: {
    async callApiInstructionAutocomplete () {
      if (this.search == null || this.search.trim().length === 0) {
        return
      }
      this.loading = true
      // Call API here
      const data = {
        search: this.search,
        limit: 20,
        offset: 0,
        dsp: this.dsp,
        orderByIO: true
      }
      const response = await this.$apiCaller.getInstructions(data)

      if (this.$apiCaller.isResponseError(response)) {
        this.$store.commit('setErrorMessageWithResponse', response)
      } else if (this.$apiCaller.isCancelError(response)) {
        console.warn('Request canceled')
      } else {
        const instructionsDict: CollectionInstruction<Instruction> = response.data as CollectionInstruction<Instruction>
        const ioIdList = Object.keys(response.data)
        this.items = []
        const advertiserField = this.$dspConfig[this.dsp].adField
        const memberField = this.$dspConfig[this.dsp].client_value
        for (let ioId of ioIdList) {
          const advertiserId = instructionsDict[ioId][0][advertiserField as keyof Instruction]
          const memberId = instructionsDict[ioId][0][memberField as keyof Instruction]
          this.items.push({
            text: `(${ioId}) ${instructionsDict[ioId][0].group_name} | ${advertiserId} | ${memberId}`,
            value: ioId
          })
        }
        console.warn(this.items)
      }
      this.loading = false
    },
    addIoIdToBulk () {
      this.$emit('add-io-id-to-bulk', this.model)
      this.model = null
    }
  },
  computed: {
    isDisabledAddInstructions (): boolean {
      return this.model === null || this.model.length === 0
    }
  },
  watch: {
    search () {
      this.callApiInstructionAutocomplete()
    }
  }
})
